import '../AboutMe.scss'
import Container from "react-bootstrap/Container";

function Title() {
  return(
    <section className="section-date">
      <Container>
        <div className="title text-center">
          <h1>Przygody, o których nie marzyliśmy</h1>
        </div>
      </Container>
    </section>
  )
}

export default Title;
