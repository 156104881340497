import "./Contact.scss";
import React from 'react';
import Email from "./Email";
import AboutMe from "../AboutMe/AboutMe";

function Contact() {
  return(
    <>
      <Email />
      <AboutMe />
    </>
  )
}

export default Contact;
